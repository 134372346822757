<template>
  <div class="Login" :class="{ BigAlert: showBigAlert }">
    <alert :show="showBigAlert==='error'" type="error" size='big' v-if="showBigAlert==='error'">
      <h2>¡MEEEC!</h2>
      <p>
        {{$t('error_verification_code')}}<br />
        <a @click="goToLink('resetPassword')" :style="`color:${theme.mainColor}`">
        {{$t('restar_process_link')}}
        </a>
      </p>
    </alert>
    <alert :show="showBigAlert==='reset_success'" type="success" size='big' v-if="showBigAlert==='reset_success'">
      <h2 class="title">{{$t('success_exclamation1')}}</h2>
      <p>{{$t('password_restore_mail',{email:form.email})}}</p>
    </alert>
    <alert :show="showBigAlert==='change_success'" type="success" size='big' v-if="showBigAlert==='change_success'">
      <h2 class="title">{{$t('success_exclamation2')}}</h2>
      <p>
        {{$t('password_restored_success1')}} <a @click="goToLink('login')" :style="`color:${theme.mainColor}`">{{$t('password_restored_success2')}}</a> {{$t('password_restored_success3')}}
      </p>
    </alert>

    <div class="Login__info" v-if="!codeAction && !showBigAlert">
      <h2 class="Login__info__subtitle">{{$t('password_restore_step1')}}</h2>
      <h2 class="Login__info__title">{{$t('password')}}</h2>
      <div class="Login__info__text">
        <p>
          {{$t('password_restore_step3')}}
        </p>
      </div>
    </div>

    <alert
      :show="alert.text"
      :type="alert.type"
      v-if="!codeAction"
    >{{ alert.text }}</alert>

    <div class="Login__form" v-if="!codeAction && !showBigAlert">
      <form class="form-row" @submit.prevent="requestReset">
        <text-input
          id="email"
          :label="$t('profile_personalization_email')"
          v-model="form.email"
          v-on:typing="checkEmail"
          isAuthInput
        />
        <Button
          color="accent"
          :disabled="Boolean(!form.email || alert.type)"
          @click="requestReset"
        > {{$t('action_send')}} <Spinner v-show="loading" />
        </Button>
      </form>
    </div>

    <div class="Login__info" v-if="codeAction && !showBigAlert">
      <h2 class="Login__info__subtitle">{{$t('password_restore_step2')}}</h2>
      <h2 class="Login__info__title">{{$t('password')}}</h2>
      <div class="Login__info__text">
        <p>
          {{$t('password_restore_step4')}}
        </p>
      </div>
    </div>

    <alert
      :show="alert.text"
      :type="alert.type"
      v-if="codeAction"
    >{{ alert.text }}</alert>

    <div class="Login__form" v-if="codeAction && !showBigAlert">
      <form @keyup.enter="requestChange">
        <text-input
          id="newPass"
          type="password"
          :label="$t('password_new')"
          v-model="form.newPass"
          @typing="crossCheckPass"
          isAuthInput
        />
        <text-input
          id="confirmNewPass"
          type="password"
          :label="$t('password_confirm')"
          v-model="form.confirmNewPass"
          @typing="crossCheckPass"
          isAuthInput
        />
        <Button
          color="accent"
          :disabled="invalidPasswords"
          @click="requestChange">
          {{$t('action_modify')}} <Spinner v-show="loading" />
        </Button>
      </form>
    </div>
  </div>
</template>

<script>
  import TextInput                from '@/components/TextInput.vue';
  import Button                   from '~/components/Button';
  import Spinner                  from '~/components/Spinner.vue';
  import Alert                    from '@/components/Alert.vue';
  import store                    from '@/store';
  import {
    USER_PASSWORD_RESET,
    USER_PASSWORD_RESET_NEW,
    USER_VERIFY_ACTIONCODE
  } from '@/store/user.module';
  import {
    verifyEmail as isValidEmail,
    fetchUserProfile,
    debounce
  }  from '@/utils';
  import { mapGetters } from "vuex";

  export default {
    name: 'ResetPasswordPage',

    components: {
      TextInput,
      Button,
      Spinner,
      Alert
    },

    data() {
      return {
        form          : { email: null, newPass: null, confirmNewPass: null },
        alert         : { text:  null, type:    null                       },
        showBigAlert  : '',
        loading       : false,
        // The next one is required for "debounce" to work.
        debounced     : null
      };
    },

    computed: {
      codeAction ( ) {
        return this.$route.query.codeAction || null;
      },
      userID ( ) {
        return this.$route.query.userId     || null;
      },
      invalidPasswords ( ) {
        return Boolean(
          !this.form.newPass || !this.form.confirmNewPass || this.alert.type
        );
      },
      ...mapGetters([
        "theme"
      ]),
    },

    methods: {
      checkEmail ( value ) {
        debounce( this, ( ) => {
          this.alert = !isValidEmail( this.form.email )
            ? { text: this.$t('error_password_restore_valid_email'), type: 'warning' }
            : { text: '', type: '' }
        }, 500 );
      },

      crossCheckPass ( value, id ) {
        debounce( this, ( ) => {
          this.form[id] = value;
          const { newPass, confirmNewPass } = this.form;
          if ( newPass && confirmNewPass ) {
            this.alert = newPass !== confirmNewPass
              ? { text: this.$t('error_password_not_same2'), type: 'warning' }
              : { text: '', type: '' };
          } else {
            this.alert = { text: '', type: '' };
          }
        }, 500 );
      },

      requestReset ( e ) {
        this.loading = true;

        store.dispatch( USER_PASSWORD_RESET, { email : this.form.email } )
          .then( ( { data } ) => {
            this.showBigAlert = 'reset_success';
            // this.alert = {
            //   text: `Se ha enviado un correo a ${ this.form.email } con instrucciones.`,
            //   type: 'success'
            // }
            this.loading = false;
          } )
          .catch( error => {
            this.alert = {
              text: this.$t('error_email_not_exists'),
              type: 'error'
            }
            this.loading = false;
          } );
      },

      requestChange ( ) {
        if ( this.invalidPasswords ) return;
        this.loading = true;

        store.dispatch( USER_PASSWORD_RESET_NEW, {
          userID    : this.userID,
          codeAction: this.codeAction,
          password  : this.form.newPass
        } )
          .then( ( { data } ) => {
            this.showBigAlert = 'change_success';
            this.loading = false;
          } )
          .catch( error => {
            this.showBigAlert = 'error';
            this.loading = false;
          } );
      },

      goToLink( link ) {
        this.showBigAlert = '';
        this.$router.push( { name: link, params: { origin: this.$route.params.origin, } } )
      }
    },

    created ( ) {
      const { userID, codeAction } = this;

      if ( userID && codeAction ) {
        store.dispatch( USER_VERIFY_ACTIONCODE, { userID, codeAction } )
          .catch( error => {
            this.showBigAlert = 'error';
            // this.alert = {
            //   text: `Ha ocurrido un error con la autorización.`,
            //   type: 'error'
            // }
          } );
      }
    }
  };
</script>

<style scoped lang="scss">
  .BigAlert {
    padding           : 2rem !important;
    height            : 50%;
  }

  .Login {

    &__form {
      padding         : 20px 60px;

      form {
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 10px;
        display: flex;
      }
      .form-row {
        flex-direction: row;
        align-items   : flex-end;
      }
    }

    &__info {

      &__text p {
        padding       : 0.5rem 0;
      }

      &__title, &__subtitle {
        text-transform: uppercase;
      }

      &__subtitle {
        font-size     : 1.2rem !important;
        line-height   : 1.2rem;
        border-bottom : none !important;
      }

      &__title {
        color         : var( --primary-color );
        font-size     : 2.2rem !important;
        line-height   : 2.2rem;

        @media only screen and ( max-width: 300px ) {
          font-size   : 1.8rem !important;
        }
      }
    }
  }

  @media only screen and ( max-width: 769px ) {
    .Login__form {
      padding         : 0;
      align-items     : center;

      form {
        width         : 75%;
        padding       : 1rem 0;
        flex-direction: column !important;
      }
    }
  }
</style>
